
import { ActionType } from "../../interfaces/store/assistantStore"
import { useAssistantStore } from "./useAssistantStore"

export const useAssistantActions = () => {
    const { dispatch } = useAssistantStore()

    const setSparkleDropdown = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_SPARKLE_DROPDOWN,
            payload
        })
    }

    const setOriginalMessage = (payload: string) => {
        return dispatch({
            type: ActionType.SET_ORIGINAL_MESSAGE,
            payload
        })
    }

    const setSelectedTone = (payload: string) => {
        return dispatch({
            type: ActionType.SET_SELECTED_TONE,
            payload
        })
    }

    const setFetchingAiMessage= (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_FETCHING_AI_MESSAGE,
            payload
        })
    }

    const resetAssistantStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    return {
        setSparkleDropdown,
        setOriginalMessage,
        setSelectedTone,
        setFetchingAiMessage,
        resetAssistantStore
    }
}


