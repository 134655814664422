import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppStoreProvider } from './stores/application/useAppStore';
import { AuthStoreProvider } from './stores/auth/useAuthStore';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConnectionsStoreProvider } from './stores/connections/useConnectionsStore';
import { TagsStoreProvider } from './stores/tags/useTagsStore';
import { TasksStoreProvider } from './stores/tasks/useTasksStore';
import { NotesStoreProvider } from './stores/notes/useNotesStore';
import { TemplateStoreProvider } from './stores/templates/useTemplateStore';
import { WorkspaceUsersStoreProvider } from './stores/workspaceUsers/useWorkspaceUsersStore';
import { HubspotStoreProvider } from './stores/hubspot/useHubspotStore';
import { AssistantStoreProvider } from './stores/assistant/useAssistantStore';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <AppStoreProvider>
      <AuthStoreProvider>
        <AssistantStoreProvider>
          <TemplateStoreProvider>
            <NotesStoreProvider>
              <TasksStoreProvider>
                <WorkspaceUsersStoreProvider>
                  <HubspotStoreProvider>
                    <ConnectionsStoreProvider>
                      <TagsStoreProvider>
                      <App />
                      </TagsStoreProvider>
                    </ConnectionsStoreProvider>
                  </HubspotStoreProvider>
                </WorkspaceUsersStoreProvider>
              </TasksStoreProvider>
            </NotesStoreProvider>
          </TemplateStoreProvider>
        </AssistantStoreProvider>
      </AuthStoreProvider>
    </AppStoreProvider>
  </QueryClientProvider>
);

/* // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); */
