import { FunctionComponent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import FormControl from "../../components/FormControl";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Row from "../../components/Row";
import CustomTextArea from "../../components/CustomTextAreaV2";
import ActionText from "../../components/ActionText";
import { useTemplateHandlers } from "../../services/actions/template/useTemplateHandlers";
import { AppRoutes, SidebarTypes } from "../../interfaces/store/appStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import Select from "../../components/select/Select";
import Text from "../../components/Text";
import PrivateCheckBoxControl from "../PrivateCheckBoxControl";
import { styles } from "../../styles/themes/style";
import { useNavigate } from "react-router-dom";
import SvgFragment from "../SvgFragment";
import { DropdownButton } from "react-bootstrap";
import Assistant from "../assistant/Assistant";
import { useAssistantStore } from "../../stores/assistant/useAssistantStore";

interface ICreateEditTemplateFormProps {
    template?: any
    isBusiness?: boolean
    formType: string,
    duplicate?: boolean,
    onMessageSaveHandler?: (e: string) => {} //this should be reused no matter the
}

interface InputEvent {
    target: {
        value: string
    }
}

interface TextareaEvent {
    target: any;
}

const CreateEditTemplateForm: FunctionComponent<ICreateEditTemplateFormProps> = ({template, isBusiness, formType, duplicate = false, onMessageSaveHandler}) => {
    const navigate = useNavigate()
    const textareaRef = useRef(null)

    const isMessage = formType === 'message' ? true : false
    const isTemplate = formType === 'template' ? true : false

    const { createTemplateHandler, updateTemplateHandler, getTemplatesSelectHandler } = useTemplateHandlers()
    const { closeSidebar } = useAppHandlers()
    const { store: { fetchingAiMessage, sparkleDropdown } } = useAssistantStore()

    const [name, setName] = useState<string>('')
    const [content, setContent] = useState('')
    const [isPrivate, setIsPrivate] = useState(true)
    const [cursorPosition, setCursorPosition] = useState(0)

    const [templates, setTemplates] = useState([])
    const [nameError, setNameError] = useState('')
    const [contentError, setContentError] = useState('')

    useEffect(() => {
        if(isMessage) getTemplates()
    }, [])

    const getTemplates = async () => {
        const { templates } = await getTemplatesSelectHandler()
        const optionTemplates = templates.map((template: any) => {
            return {
                label: template.name,
                value: template.content
            }
        })
        setTemplates(optionTemplates)
    }

    const changeContentHandler = ({ target: { value } }: InputEvent) => {
        setContent(value)
        validateContent(value)
    }

    const onTextareaBlur = ({ target: { selectionStart } }: TextareaEvent) => {
        if (selectionStart !== null) {
          setCursorPosition(selectionStart);
        }
    }

    const addPlaceholderHandler = (placeholder: string, placeholderLength: number) => {
        if (content.trim().length + placeholderLength > 2000) return
    
        if (content.length === cursorPosition) {
            if (content) {
                setContent(`${content.trim()} {{${placeholder}}}`)
                setCursorPosition(content.trim().length + placeholderLength)
            } else {
                setContent(`{{${placeholder}}}`)
                setCursorPosition(placeholderLength - 1)
                setContentError('')
            }
        } else {
            const stringBefore = content.substring(0, cursorPosition).trim()
            const stringAfter = content.substring(cursorPosition).trim()
            setContent(`${stringBefore} {{${placeholder}}} ${stringAfter}`)
            setCursorPosition(stringBefore.length + placeholderLength)
        }
    }

    const validateName = (value: string) => {
        if (!value.trim()) {
            setNameError('Name is required')
            return false
        }
        setNameError('')
        return true
    }

    const validateContent = (value: string) => {
        if (!value.trim()) {
            setContentError('Message content is required')
            return false
        }
        setContentError('')
        return true
    }

    const onSaveHandler = () => {
        if (isTemplate && !validateName(name)) {
            return
        }

        if (!validateContent(content)) {
            return
        }

        if(isTemplate) {
            if(!template || (template && duplicate)) {
                console.log(!template ? 'Create New Template' : (template && duplicate) ? 'Duplicate template' : null)
                createTemplateHandler((
                    !template ? 'created': (template && duplicate) && 'duplicated'
                ), {
                    name: name.trim(),
                    content: content.trim(),
                    isPrivate: isPrivate
                })
            } else {
                console.log('Update template')
                updateTemplateHandler({
                    _id: template._id,
                    name: name.trim(),
                    content: content.trim(),
                    isPrivate: isPrivate
                })
            }
        } else if(isMessage) {
            onMessageSaveHandler && onMessageSaveHandler(content.trim())
        }
        
        if(isTemplate) {
            closeSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE)
        } else if(isMessage) {
            closeSidebar(SidebarTypes.BULK_MESSAGE)
        } 
    }


    useEffect(() => {
        if(template) {
            setName(duplicate ? `Copy ${template.name}` : template.name)
            setContent(template.content)
            setIsPrivate(template.isPrivate)
        }
    }, [])

    return (
        <Column gap="20px">
            {isTemplate && 
                <FormControl $orientation="column" label="Name" error={nameError}>
                    <Input 
                        maxLength={100} 
                        prefilledValue={name} 
                        placeholder="Enter template name" 
                        onChangeHandler={(e: any) => {
                            setName(e)
                            validateName(e)
                        }} 
                    />
                </FormControl>}
            {isMessage && 
                <FormControl $orientation="column">
                    <Select 
                        placeholder="Choose message template..."
                        isSearchable={false}
                        options={templates}
                        onChange={(e: any) => setContent(e.value)}
                    />
                </FormControl>}
            <ActionTextRow>
                <ActionText backgroundColor={styles.colors.primary100} onClickHandler={() => addPlaceholderHandler('firstName', 14)}>{'+ FirstName'}</ActionText>
                <ActionText backgroundColor={styles.colors.primary100} onClickHandler={() => addPlaceholderHandler('lastName', 13)}>{'+ LastName'}</ActionText>
                <ActionText backgroundColor={styles.colors.primary100} onClickHandler={() => addPlaceholderHandler('company', 12)}>{'+ Company'}</ActionText>
                <ActionText backgroundColor={styles.colors.primary100} onClickHandler={() => addPlaceholderHandler('senderName', 15)}>{'+ SenderName'}</ActionText>
                <Assistant content={content} setContent={setContent} />
            </ActionTextRow>
            <FormControl $orientation="column" error={contentError}>
                <CustomTextArea
                    isDisabled={fetchingAiMessage}
                    ref={textareaRef}
                    maxLength={2000} 
                    style={{ height: '250px' }} 
                    placeholder='Enter a template message here...'
                    onChange={changeContentHandler}
                    onBlur={onTextareaBlur}
                    value={content}
                />
            </FormControl>
            {sparkleDropdown && <Text $description>Important notice: AI is capable of errors. Check its output and accuracy before using the data or sending messages.</Text>}
            {isMessage && <Text $red>Important notice: The message will only be sent to your 1st-degree connections.</Text>}
            {isTemplate && <Column gap="10px">
                <PrivateCheckBoxControl type={"template"} disabled={!isBusiness} value={isPrivate} onChangeHandler={() => setIsPrivate(!isPrivate)} />
                {!isBusiness && <StyledActionText icon='lock2'
                    onClickHandler={(e: any) => {
                        e.stopPropagation()
                        window.open(`${window.location.origin}${AppRoutes.BILLING}`, '_blank')
                    }}
                >
                    {'Upgrade to Business'}
                </StyledActionText>}
            </Column>}
            <ButtonWrapper>
                <Button 
                    $type="cancel" 
                    onClickHandler={() => isTemplate ? closeSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE) : closeSidebar(SidebarTypes.BULK_MESSAGE)}
                >
                    Cancel
                </Button>
                <Button 
                    $type="blue"  
                    onClickHandler={onSaveHandler}
                    disabled={(isTemplate && !name.trim()) || !content.trim()}
                >
                    {isTemplate ? (template ? (template && duplicate) ? 'Duplicate' : 'Save' : 'Save') : 'Send'}
                </Button>
            </ButtonWrapper>
        </Column>
    )
}
const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`
const StyledActionText = styled(ActionText)`
    span {    
        color: ${styles.colors.black300};
        font-size: 12px;
    }

    &:hover{
        path { fill: ${styles.colors.primary400} }
    }

    div {
        margin-right: 4px;
        svg {
            margin-bottom: 4px;
        }
    }
`
const ActionTextRow = styled(Row)`
    flex-wrap: wrap;
    gap: 10px;
`
const StyledDiv = styled.div`
  &:hover svg path {
    stroke: ${styles.colors.primary600};
  }
`;
export default CreateEditTemplateForm