import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"

export const useAssistantRequest = () => {
    const { sendRequest } = useAxios()

    const route = '/ai-assistant'

    const generateMessage = (payload: any) => {
        return sendRequest({
            slug: `ai-assistant`,
            url: `${route}/sparkle`,
            method: 'POST',
            payload: payload
        })
    };

    return {
        generateMessage
    };
}

