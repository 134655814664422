import { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { styles } from "../../styles/themes/style";
import AiDropdownItem from "./components/AiDropdownItem";
import ActionText from "../../components/ActionText";
import { useAssistantActions } from "../../stores/assistant/useAssistantActions";
import { useAssistantStore } from "../../stores/assistant/useAssistantStore";
import DropdownItem from "../../components/dropdown/DropdownItem";

interface ISetToneProps {
    isDisabled?: boolean
    dropdown: string
    setDropdown: (e: string) => void
    generateMessage: (e: any, i: any) => void
}

const SetTone: FunctionComponent<ISetToneProps> = ({ isDisabled, dropdown, setDropdown, generateMessage }) => {
    const tones = ['Neutral', 'Casual', 'Formal', 'Confident', 'Empathetic', 'Direct', 'Friendly', 'Engaging', 'Personable', 'Witty']

    const { setSelectedTone } = useAssistantActions()
    const { store: { selectedTone } } = useAssistantStore()

    const onClickHandler = (tone: string) => {
        setSelectedTone(tone)
        generateMessage('change-tone', tone)
    }

    return (
        <Container>
            <AiDropdownItem padding="10px 15px" isDisabled={isDisabled} isDropdownOpen={dropdown === 'tone'} showArrow onClick={setDropdown} label="Set tone" svg="setTone" />
            {dropdown === 'tone' && <Dropdown>
                {tones.map((tone: any) => (
                    <DropdownItem>
                        <ActionText isSelected={selectedTone === tone} padding="5px" backgroundColor={styles.colors.primary100} onClickHandler={() => onClickHandler(tone)}>{tone}</ActionText>
                    </DropdownItem>
                ))}
                </Dropdown>}
        </Container>
    )
}

const Container = styled.div``

const Dropdown = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 6px 15px;

    & .dropdown-item {
        width: auto;
        padding: 0;
    }

    &:hover .dropdown-item {
        background-color: transparent;
    }
`

export default SetTone