import { FunctionComponent, ReactNode, useState } from "react";
import SvgFragment from "../../SvgFragment";
import DropdownMenu from "../../../components/dropdown/DropdownMenu";
import styled from "styled-components";
import { styles } from "../../../styles/themes/style";
import DropdownItem from "../../../components/dropdown/DropdownItem";
import Text from "../../../components/Text";
import Column from "../../../components/Column";
import Row from "../../../components/Row";

interface IStylesProps {
    isDropdownOpen?: boolean
    cursor?: boolean
    fill?: string
    padding?: string
    isDisabled?: any
}

interface IAiDropdownItemProps extends IStylesProps {
    showArrow?: boolean
    svg?: string,
    label?: string,
    children?: ReactNode,
    onClick?: (e: any) => void
}


const AiDropdownItem: FunctionComponent<IAiDropdownItemProps> = ({isDisabled, padding, isDropdownOpen, showArrow, svg, label = '', onClick }) => {

    const onClickHandler = (e: any) => {
        if(onClick) onClick(e)
    }

    return (
        <StyledRow isDisabled={isDisabled} padding={padding} fill={["Set tone", "Make punchier", "Condense message", "Generate message", "Reset to original message"].includes(label) ? 'stroke' : 'fill'} cursor={!!onClick} onClick={(e: any) => onClick ? onClickHandler(e) : undefined} className={label} alignItems gap="6px">
            <SvgFragment type={svg} />
            <Text $label>{label}</Text>
            {showArrow && <StyledArrow className="ai-assistant-dropdown-item" isDropdownOpen={isDropdownOpen}>
                <SvgFragment type="arrowDown" />
            </StyledArrow>}
        </StyledRow>
    )
}

const StyledRow = styled(Row)<IStylesProps>`
    padding: ${({padding}) => padding};

    ${({cursor, isDisabled}) => (cursor && !isDisabled) && 'cursor: pointer;'}

    ${({isDisabled, fill}) => !isDisabled && `

        &:hover {
        background-color: ${styles.colors.black100};

        & svg path { ${fill}: ${styles.colors.primary600}; }
        
        & .ai-assistant-dropdown-item {
            & svg path { 
                fill: ${styles.colors.primary600} !important; 
                stroke: none; 
            }
        }
    }
    
    `}


`
const StyledArrow = styled.div<IStylesProps>`
    margin-left: auto;

    & svg {
        ${({isDropdownOpen}) => isDropdownOpen && 'transform: rotate(180deg);'}
        
        transition: transform 0.1s ease-in-out;
    }
`

export default AiDropdownItem