import { FunctionComponent } from "react";
import styled from "styled-components";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import SvgFragment from "../SvgFragment";
import DropdownItem from "../../components/dropdown/DropdownItem";
import { IConnection } from "../../interfaces/models/connection";
import Row from "../../components/Row";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { useAuthHandlers } from "../../services/actions/auth/useAuthHandlers";
import { styles } from "../../styles/themes/style";
import useMediaQuery from "../../components/useMediaQuery";
import { useTemplateHandlers } from "../../services/actions/template/useTemplateHandlers";
import { useTemplateStore } from "../../stores/templates/useTemplateStore";

interface IBulkConnectionActionsDropdownProps {
  className?: string;
  selectedConnections?: IConnection[];
  selectedAllConnections?: boolean;
}

const TemplatesBulkActionsDropdown: FunctionComponent<
  IBulkConnectionActionsDropdownProps
> = () => {
  const { getTemplatesHandler, bulkDeleteTemplatesHandler } =
    useTemplateHandlers();
  const {
    store: {
      templatesFilters: { selectedTemplatesIds, isAllSelected },
    },
  } = useTemplateStore();
  const {
    store: { user },
  } = useAuthStore();
  const { saveUserPreferencesHandler } = useAuthHandlers();
  const isDefaultTemplates = user?.preferences?.hideDefaultTemplates;

  const isResponsiveContainerSmall = useMediaQuery("(max-width: 1260px)");

  const bulkConnectionActions = [
    {
      icon: "trashCan",
      title: `Delete Templates`,
      slug: "deleteTemplates",
      enabled: selectedTemplatesIds.length > 0 || isAllSelected,
      visible: true,
      destructive: true,
      onClickHandler: () => bulkDeleteTemplatesHandler(),
    },
    {
      icon: isDefaultTemplates ? "show" : "hide",
      title: `${isDefaultTemplates ? "Show" : "Hide"} default templates`,
      slug: "hide",
      enabled: true,
      visible: isResponsiveContainerSmall,
      destructive: false,
      onClickHandler: () => onHideDefaultTemplatesHandler(),
    },
  ];

  const onHideDefaultTemplatesHandler = async () => {
    await saveUserPreferencesHandler({
      hideDefaultTemplates: !isDefaultTemplates,
    });
    getTemplatesHandler();
  };

  return (
    <DropdownMenu
      tooltipMessage={"More actions"}
      title={
        <SvgFragmentWrapper disabled={false}>
          More <SvgFragment type="arrowDown" />
        </SvgFragmentWrapper>
      }
      $hideToggle
    >
      {bulkConnectionActions.map(
        (connectionAction, index) =>
          connectionAction.visible && (
            <DropdownItem
              $disabled={!connectionAction.enabled}
              key={index}
              $danger={connectionAction.destructive}
              onClickHandler={(e: any) =>
                connectionAction.onClickHandler &&
                connectionAction.onClickHandler()
              }
            >
              <Row alignItems gap="5px">
                <SvgFragment type={connectionAction.icon} />
                {connectionAction.title}
              </Row>
            </DropdownItem>
          )
      )}
    </DropdownMenu>
  );
};

const SvgFragmentWrapper = styled.div<{ disabled: boolean }>`
  width: 75px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ disabled, theme: { quinaryColor } }) =>
    disabled ? quinaryColor : styles.colors.primary600};
`;

export default TemplatesBulkActionsDropdown;
