import { FunctionComponent, useEffect, useState } from "react";
import SvgFragment from "../SvgFragment";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import styled from "styled-components";
import { styles } from "../../styles/themes/style";
import DropdownItem from "../../components/dropdown/DropdownItem";
import SetTone from "./SetTone";
import Text from "../../components/Text";
import SetTranslate from "./SetTranslate";
import AiDropdownItem from "./components/AiDropdownItem";
import { useAssistantActions } from "../../stores/assistant/useAssistantActions";
import { useAssistantStore } from "../../stores/assistant/useAssistantStore";
import { useAssistantHandler } from "../../services/actions/assistant/useAssistantHandler";

interface IAiAssistantProps {
    content?: string
    setContent?: (e: string) => void
    onDropdownToggle?: (isOpen: boolean) => void;
}

const Assistant: FunctionComponent<IAiAssistantProps> = ({content = '', setContent, onDropdownToggle}) => {

    const { generateMessageHandler } = useAssistantHandler()
    const { setOriginalMessage, setFetchingAiMessage, setSparkleDropdown } = useAssistantActions()
    const { store: { originalMessage } } = useAssistantStore()

    const [dropdown, setDropdown] = useState<string>('')
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const toggleDropdown = (currentDropdown: string, target: string): string => currentDropdown === target ? '' : target

    const dropdownItems = [
        { handler: 'improve-grammar', label: 'Fix spelling', svg: 'spelling' },
        { handler: 'make-punchier', label: 'Make punchier', svg: 'punchier' },
        { handler: 'condense-message', label: 'Condense message', svg: 'condense' },
        // { handler: 'generate', label: 'Generate message', svg: 'sparkle' },
    ]

    useEffect(() => {
        if(content.length < 5) setOriginalMessage('')
    }, [content])

    useEffect(() => {
        if(!isDropdownOpen) setDropdown('')
    }, [isDropdownOpen])

    const handleDropdownToggle = (isOpen: boolean) => {
        setIsDropdownOpen(isOpen)
        setSparkleDropdown(isOpen)
    } 

    const generateMessage = async (action?: string, props?: any) => {
        setFetchingAiMessage(true)

        if (action === 'reset') {
            setContent?.(originalMessage)
            return
        }
    
        setOriginalMessage(content)
    
        const commonData = { original_message: content }
        let payload = {}
    
        switch (action) {
            case 'change-tone':
                payload = {
                    type: 'change-tone',
                    data: { ...commonData, new_tone: props }
                }
                break
    
            case 'translate':
                payload = {
                    type: 'translate',
                    data: { ...commonData, new_language: props }
                }
                break
    
            default:
                if (dropdownItems.some((item: any) => item.handler === action)) {
                    payload = {
                        type: action,
                        data: commonData
                    }
                }
        }
    
        if (Object.keys(payload).length) {
            const newMessage = await generateMessageHandler(payload)
            if(newMessage) setContent?.(newMessage)
        }
    
        setFetchingAiMessage(false)
    }

    const isDisabled = content.length <= 5

    return (
        <StyledDropdownMenu hideArrow onToggle={handleDropdownToggle} SVGtype="aiAssistant" svgPath>
            <DropdownItem $disabled><Text $heading5>Refine the message</Text></DropdownItem>
            <SetTone
                isDisabled={isDisabled}
                generateMessage={generateMessage}
                dropdown={dropdown}
                setDropdown={() => isDisabled ? undefined : setDropdown((prev) => toggleDropdown(prev, 'tone'))}
            />
            <SetTranslate
                isDisabled={isDisabled}
                generateMessage={generateMessage} 
                dropdown={dropdown}
                setDropdown={() => isDisabled ? undefined : setDropdown((prev) => toggleDropdown(prev, 'translate'))}
            />
            {dropdownItems.map(({ handler, label, svg }) => (
                <DropdownItem $disabled={isDisabled} onClickHandler={() => generateMessage(handler, null)}>
                    <AiDropdownItem isDisabled={isDisabled} padding="5px 0" key={handler} label={label} svg={svg}/>
                </DropdownItem>
            ))}
            <Separator />
            <DropdownItem $disabled={isDisabled} onClickHandler={() => generateMessage('reset', null)}>
                <AiDropdownItem isDisabled={isDisabled} padding="5px 0" label="Reset to original message" svg="undo"/>
            </DropdownItem>
        </StyledDropdownMenu>
    )
}

const StyledDropdownMenu = styled(DropdownMenu)`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${styles.colors.primary100} !important;
    border-radius: 5px;

    & .actions-dropdown + div  {
        width: 350px;
        overflow-x: hidden;
        max-height: 600px;
    }
`

const Separator = styled.div`
    width: 350px;
    height: 2px;
    margin: 4px 0;
    background-color: #EBEFF4 !important;
`
export default Assistant