//Enums
export enum ActionType {
    SET_TEMPLATES = "SET_TEMPLATES",
    SET_TEMPLATES_FILTERS= "SET_TEMPLATES_FILTERS",
    SET_TEMPLATES_PARAMETERS = "SET_TEMPLATES_PARAMETERS",
    RESET_STORE = "RESET_STORE"
}

//Interfaces
export type ITemplatesParametersState = {
    page?: number,
    pageSize?: number,
    sort?: string,
    total?: undefined | number
    totalOwnerCount?: undefined | number
}

export type ITemplatesFiltersState = {
    searchByName?: string,
    createdAtTo: any,
    createdAtFrom: any,
    templateAccessType: object | undefined,
    usersMutualCondition: string,
    selectedUsersMutual: object[]
    selectedTemplatesIds?: string[]
    isAllSelected?: boolean
}

//States
export type TTemplateState = {
    templates: any[];
    templatesParameters: ITemplatesParametersState
    templatesFilters: ITemplatesFiltersState
}

interface ISetTemplatesAction {
    type: ActionType.SET_TEMPLATES;
    payload: TTemplateState["templates"];
}
  
interface ISetTemplatesParametersAction {
    type: ActionType.SET_TEMPLATES_PARAMETERS;
    payload: TTemplateState["templatesParameters"];
}

interface ISetTemplatesFiltersAction {
    type: ActionType.SET_TEMPLATES_FILTERS;
    payload: TTemplateState["templatesFilters"];
}

interface IResetStoreAction {
    type: ActionType.RESET_STORE;
}

export type TTemplatesAction = ISetTemplatesAction | ISetTemplatesParametersAction | ISetTemplatesFiltersAction | IResetStoreAction;