import { FormEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DateComponent from "../../components/DateComponent";
import AdvancedFilterFragment from "../AdvancedFilterFragment";
import { useTasksHandlers } from "../../services/actions/tasks/useTasksHandlers";
import FormControl from "../../components/FormControl";
import Select from "../../components/select/Select";
import { useTasksStore } from "../../stores/tasks/useTasksStore";
import Text from "../../components/Text";
import Tag from "../../components/Tag";
import { styles } from "../../styles/themes/style";
import Row from "../../components/Row";
import ActionText from "../../components/ActionText";
import Button from "../../components/Button";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { useTagsHandlers } from "../../services/actions/tags/useTagsHandlers";
import { IUser } from "../../interfaces/models/connection";
import ProfilePhoto from "../../components/ProfilePhoto";
import Column from "../../components/Column";
import FilterDropdownButton from "../dropdowns/FilterDropdownButton";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import DropdownItem from "../../components/dropdown/DropdownItem";
import Checkbox from "../../components/Checkbox";

interface IFilterTasksFormProps { }

interface PriorityOption {
    value: number;
    label: string;
    component?: JSX.Element;
}

const typeOptions = [
    { value: 'No Type', label: 'No Type' },
    { value: 'General', label: 'General' },
    { value: 'Deadline', label: 'Deadline' },
    { value: 'In-person meeting', label: 'In-person meeting' },
    { value: 'Online meeting', label: 'Online meeting' },
    { value: 'Email', label: 'Email' },
    { value: 'Call', label: 'Call' },
    { value: 'Book a meeting', label: 'Book a meeting' },
    { value: 'LinkedIn message', label: 'LinkedIn message' },
    { value: 'LinkedIn connection add', label: 'LinkedIn connection add' }
]

const repeatingOptions = [
    { value: 'all', label: 'All' },
    { value: 'Never', label: 'Never' },
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' }
]

const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'completed', label: 'Completed' },
    { value: 'outstanding', label: 'Outstanding' },
    { value: 'overdue', label: 'Overdue' }
]

const priorityOptions: PriorityOption[] = [
    { value: -1, label: 'All Priority Levels', component: <Text $lighter>All Priority Levels</Text> },
    { value: 0, label: 'No Priority Level', component: <Text $lighter>No Priority Level</Text> },
    { value: 1, label: 'Low', component: <Tag title="Low" $color={styles.colors.blue600} /> },
    { value: 2, label: 'Medium', component: <Tag title="Medium" $color={styles.colors.green500} /> },
    { value: 3, label: 'High', component: <Tag title="High" $color={styles.colors.orange600} /> },
    { value: 4, label: 'Urgent', component: <Tag title="Urgent" $color={styles.colors.red600} /> }
]

export interface IOwnerOption {
    value: string,
    label: ReactNode,
    id: string,
    selected: boolean,
    object: Object
}

const FilterTasksForm: FunctionComponent<IFilterTasksFormProps> = () => {
    const { store: { tasksFilters } } = useTasksStore()
    const { store: { workspace} } = useAuthStore()
    const { taskCondition, priority } = tasksFilters
    const { setTasksFiltersHandler, setClearTasksFilterHandler } = useTasksHandlers()
    const { workspaceUsersPublicAllHandler } = useTagsHandlers()
    const { closeSidebar } = useAppHandlers()

    const [selectedNames, setSelectedNames] = useState<string>('');
    const [selectOptions, setSelectOptions] = useState<IOwnerOption[]>([]);

    useEffect(() => {
        workspaceUsersHandler()
    }, []);

    const workspaceUsersHandler = async () => {
        const result = await workspaceUsersPublicAllHandler({
            condition: 'or',
            name: '',
            email: '' 
        })

        if(!selectOptions.length && result.length){
            const options = [
                { id: 'all', label: <Text>{'All Members'}</Text>, selected: false, value: 'all', object: {} },
                ...result.map((user: IUser) => ({
                    value: `${user.firstName} ${user.lastName}`,
                    label: <StyledContainer>
                        <ProfilePhoto 
                            tooltipMessage={`${user?.firstName} ${user?.lastName}`}
                            capitalLetters={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`} />
                        <Column>
                            <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                            <Text $paragraph3 $placeholder $ellipsis="0">{user?.email}</Text>
                        </Column>
                    </StyledContainer>,
                    id: user._id,
                    selected: false,
                    object: user
                }))
            ];
            
            setSelectOptions(options);
        }
    };

    const onSelectedItemHandler = (items: PriorityOption[], item: any) => {
        let values: { value: number; label: string; }[] = []

        const hasAllValues = items.filter((option: any) => [1, 2, 3, 4].includes(option.value)).length === 4
        const hasNoValues = priority.some((item: any) => item.value === 0)

        const selectAllValues = item.value === -1
        const selectNoValues = item.value === 0

        if(selectAllValues) {
            if(hasAllValues) {
                values = []
            } else {
                values = priorityOptions.filter(option => [-1, 1, 2, 3, 4].includes(option.value)).map(({ component, ...rest }) => rest)
            }
        } else if(selectNoValues) {
            if(hasNoValues) {
                values = items.filter(option => option.value !== 0).map(({ component, ...rest }) => rest)
            } else {
                values = priorityOptions.filter(option => option.value === 0).map(({ component, ...rest }) => rest)
            }
        } else {
            values = items.filter((option: any) => ![-1, 0].includes(option.value))
        }

        setTasksFiltersHandler({...tasksFilters, priority: values})
    }

    const onSelectedTabHandler = (e: any) => {
        setTasksFiltersHandler({...tasksFilters, taskCondition: e})
    }

    const onResetSelectionsHandler = () => {
        setTasksFiltersHandler({...tasksFilters, priority: [], taskCondition: 'And'})
    }

    const resetFilterHandler = (e: any) => {
        setTasksFiltersHandler({...tasksFilters, type: e})
    }

    const showResults = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        closeSidebar(SidebarTypes.TASKS_FILTER)
    }, [tasksFilters]);

    const resetFormHandler = useCallback(() => {
        setClearTasksFilterHandler();
        setSelectOptions([]);
        workspaceUsersHandler();
    }, [tasksFilters, setClearTasksFilterHandler]);

    const setSelectedOwners = (newlySelectedUsers: IOwnerOption[]) => {
        const updatedFilter = {
            ...tasksFilters,
            owners: [...newlySelectedUsers?.map((user) => user.object)]
        }; 
        setTasksFiltersHandler(updatedFilter);
    };

    const resetSelectedOwners = () => {
        const updatedFilter = {
            ...tasksFilters,
            owners: []
        }; 
        setTasksFiltersHandler(updatedFilter);
    };

    const toggleItemHandler = (event: any, item: IOwnerOption) => {
        event.stopPropagation()
        let add: boolean
        let allSelected = false;
        let selectedOwnersObj: any = [];
        if(item.id === 'all'){
            add = !item.selected
            setSelectOptions(selectOptions.map((option) => {
                if (add){
                    selectedOwnersObj.push(option);
                    allSelected = true;
                }   
                return {...option, selected: add}
            }))
        } else {
            setSelectOptions(selectOptions.map((option) => {
                if(option.id === item.id) {
                    add = !option.selected
                    let newOption = {...option, selected: add}
                    if(newOption.selected) {
                        selectedOwnersObj.push(option);
                    }
                    return newOption;
                } else if(option.selected) {
                    selectedOwnersObj.push(option);
                }
                if(item.selected){
                    allSelected = false;
                }
                return {...option, selected: (option.id === 'all' && item.selected) ? false : option.selected}
            }))
        }

        if(!allSelected){ // remove 'all' if not all members are selected
            selectedOwnersObj = selectedOwnersObj.filter((item: any) => item.id !== 'all');
        }

        if(item.id !== 'all'){
            if (selectedOwnersObj.filter((item: any) => item.id !== 'all').length <= 1) {
                setSelectedNames(selectedOwnersObj.filter((item: any) => item.id !== 'all').map((o: any) => o.value).join(', '));
              } else {
                setSelectedNames(`Members: ${selectedOwnersObj.filter((item: any) => item.id !== 'all').length} selected`);
              }
        } else {
            setSelectedNames('All members');
        }

        if(selectedOwnersObj.length){
            setSelectedOwners(selectedOwnersObj);
        }
        else {
            resetSelectedOwners();
        }
    }

    const dropdownButtonMembers = <FilterDropdownButton
        svgType='user' 
        placeholder={selectedNames || 'All members'}
        />

    return (
        <StyledForm>
            <FormControl $orientation="column">
                <Select
                    placeholder="Status"
                    isSearchable={false}
                    options={statusOptions}
                    value={tasksFilters?.status ? {label: 'Status: ' + tasksFilters?.status.label} : undefined}
                    onChange={(e: any) => setTasksFiltersHandler({...tasksFilters, status: e})}
                />
            </FormControl>
            { workspace.isBusiness &&
                <StyledDropdownMenu
                    title={dropdownButtonMembers}
                    autoClose='outside'
                    drop='down'
                    selectStyle>
                        {selectOptions.map((item: any) => (
                            <DropdownItem
                            $marginTop
                                key={item.id}
                                onClickHandler={(e: any) => toggleItemHandler(e, item)}>
                                    <StyledRow>
                                        <Checkbox $height={'24px'} checked={item.selected} checkboxId={item.id} />
                                            {item.label}
                                    </StyledRow>
                            </DropdownItem> ))}
                </StyledDropdownMenu>
            }
            <DateComponent
                title='Due date'
                dateFrom={tasksFilters.endFrom ? tasksFilters.endFrom : ''}
                dateTo={tasksFilters.endTo ? tasksFilters.endTo : ''}
                dateFromValueChangedHandler={(e: any) => setTasksFiltersHandler({...tasksFilters, endFrom: e})}
                dateToValueChangedHandler={(e: any) =>  setTasksFiltersHandler({...tasksFilters, endTo: e})}
                resetFilter={() => setTasksFiltersHandler({...tasksFilters, endFrom: '', endTo: ''})}
            />
            <FormControl $orientation="column">
                <Select
                    placeholder="No type"
                    isSearchable={false}
                    options={typeOptions}
                    value={tasksFilters?.type ? {label: 'Type: ' + tasksFilters?.type.label} : undefined}
                    onChange={(e: any) => resetFilterHandler(e)}
                />
            </FormControl>
            <FormControl $orientation="column">
                <AdvancedFilterFragment
                    showSearchField={false}
                    filterOptions={priorityOptions.map((item: any) => ({
                        value: item.value,
                        label: item.component,
                        id: item.value,
                        selected: false,
                        object: item
                    }))}
                    selectedItems={priority.map((item: any) => ({ _id: item.value }))}
                    infoTitle={"Choose whether you want to get items that all selected users share (AND) or any of the selected users (OR)."}
                    inputTitle={"Priority level"}
                    tabs={[{title: 'And', slug: 'And', selected: taskCondition !== 'Or'}, {title: 'Or', slug: 'Or', selected: taskCondition === 'Or'}]} 
                    onSelectedItemHandler={onSelectedItemHandler}
                    onSelectedTabHandler={onSelectedTabHandler}
                    onResetSelectionsHandler={onResetSelectionsHandler}
                />
            </FormControl>
            <FormControl $orientation="column">
                <Select
                    placeholder="Repeating tasks"
                    isSearchable={false}
                    options={repeatingOptions}
                    value={tasksFilters?.status ? {label: 'Repeating tasks: ' + tasksFilters?.repeating.label} : undefined}
                    onChange={(e: any) => setTasksFiltersHandler({...tasksFilters, repeating: e})}
                />
            </FormControl>
            <div style={{marginTop: '16px'}}>
                <Row gap="10px" spaceBetween>
                    <ActionText danger onClickHandler={() => resetFormHandler()}>Clear filters</ActionText>
                    <Row gap="10px" alignItems>
                        <Button $mediumButton $type="blue" onClickHandler={(e: any) => showResults(e)}>Results</Button>
                    </Row>
                </Row>
            </div>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`
const StyledDropdownMenu = styled(DropdownMenu)`
    background-color: ${({theme: { secondaryColor }}) => secondaryColor} !important;
    .actions-dropdown + div {
        margin-bottom: 13px;
        width: 100%;
        max-height: 350px;
        overflow-x: hidden !important;
    }

    .actions-dropdown > span {
         color:  ${styles.colors.disabledState} !important;
    }

    .dropdown-toggle {
        &:hover, &:focus {
            outline: 2px solid ${styles.colors.primary500}
        },
     }
`
const StyledContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`
const StyledRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`
export default FilterTasksForm