import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTagsStore } from "../../stores/tags/useTagsStore";
import Input from "../../components/Input";
import AdvancedFilterFragment from "../AdvancedFilterFragment";
import { useTagsHandlers } from "../../services/actions/tags/useTagsHandlers";
import { IUser } from "../../interfaces/models/connection";
import FormControl from "../../components/FormControl";
import ProfilePhoto from "../../components/ProfilePhoto";
import Row from "../../components/Row";
import Text from "../../components/Text";
import Column from "../../components/Column";
import Select from "../../components/select/Select";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import ActionText from "../../components/ActionText";
import Button from "../../components/Button";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

interface IFilterTagsFormProps { }


const FilterTagsForm: FunctionComponent<IFilterTagsFormProps> = () => {

    const { store: { tagsFilter } } = useTagsStore()
    const { store: { workspace} } = useAuthStore()

    const { setTagsFilterHandler, workspaceUsersPublicAllHandler, setClearTagsFilterHandler } = useTagsHandlers()
    const [workspaceUsers, setWorkspaceUsers] = useState<any>([])
    const { closeSidebar } = useAppHandlers()

    const { searchByName, selectedUsersMutual, usersMutualCondition} = tagsFilter
    const infoTitleTags = "Choose whether you want to get items that have all the selected tags (AND) or any of the selected tags (OR).";
    const inputTitleMutualTags = "User Tags";

    const tagTypeOptions = [
        { value: 'all', label: 'All' },
        { value: 'yes', label: 'Public' },
        { value: 'no', label: 'Private' },  
    ];

    useEffect(() => {
        workspaceUsersHandler()
    }, [])

    const workspaceUsersHandler = async () => {
        const result = await workspaceUsersPublicAllHandler({
            condition: 'or',
            name: '',
            email: '' 
        })
        setWorkspaceUsers(result)
    }

    const setSelectedUsersMutual = (newlySelectedUsers: IUser[]) => {
        const updatedFilter = {
            ...tagsFilter,
            selectedUsersMutual: [...newlySelectedUsers]
        }; 
        setTagsFilterHandler(updatedFilter);
    };

    const resetSelectedUsersMutual = () => {
        const updatedFilter = {
            ...tagsFilter,
            selectedUsersMutual: []
        }; 
        setTagsFilterHandler(updatedFilter);
    };

    const updateForm = useCallback((control: any, newValue: string) => {
        const updatedFilter = {
            ...tagsFilter,
            [control]: newValue
        };

        setTagsFilterHandler(updatedFilter);
    }, [tagsFilter, setTagsFilterHandler]);

    const resetFormHandler = useCallback(() => {
        setClearTagsFilterHandler();
    }, [tagsFilter, setClearTagsFilterHandler]);

    const showResults = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        closeSidebar(SidebarTypes.TAG_FILTER)
    }, [tagsFilter]);

    return (
        <StyledForm>
            <FormControl $orientation="column">
                <Input
                    placeholder="Search by name..."
                    prefilledValue={searchByName}
                    onChangeHandler={(e: any) => setTagsFilterHandler({...tagsFilter, searchByName: e})}
                />
            </FormControl>
            { 
            workspace.isBusiness &&
            <><AdvancedFilterFragment
                    styledRow={true}
                    filterOptions={workspaceUsers.map((user: IUser) => ({
                        value: `${user.firstName} ${user.lastName}`,
                        label: <StyledContainer>
                            <ProfilePhoto 
                                tooltipMessage={`${user?.firstName} ${user?.lastName}`}
                                capitalLetters={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`} />
                            <Column>
                                <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                                <Text $paragraph3 $placeholder $ellipsis="0">{user?.email}</Text>
                            </Column>
                        </StyledContainer>,
                        id: user._id,
                        selected: false,
                        object: user
                    }))}
                    selectedItems={selectedUsersMutual}
                    infoTitle={infoTitleTags}
                    inputTitle={inputTitleMutualTags}
                    tabs={[{ title: 'Or', slug: 'Or', selected: usersMutualCondition === 'Or' }, { title: 'And', slug: 'And', selected: usersMutualCondition !== 'Or' }]}
                    onSelectedTabHandler={(tab: string) => updateForm('usersMutualCondition', tab)}
                    onSelectedItemHandler={setSelectedUsersMutual}
                    onResetSelectionsHandler={resetSelectedUsersMutual} />
            <FormControl $orientation="column">
                        <Select
                            placeholder="Tag type"
                            isSearchable={false}
                            options={tagTypeOptions}
                            value={tagsFilter?.tagAccessType ? { label: 'Tag type: ' + tagsFilter?.tagAccessType.label } : undefined}
                            onChange={(e: any) => updateForm('tagAccessType', e)} />
            </FormControl></>
            }
            <div style={{marginTop: '16px'}}>
                <Row gap="10px" spaceBetween>
                    <ActionText danger onClickHandler={() => resetFormHandler()}>Clear filters</ActionText>
                    <Row gap="10px" alignItems>
                        <Button $mediumButton $type="blue" onClickHandler={(e: any) => showResults(e)}>Results</Button>
                    </Row>
                </Row>
            </div>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`
const StyledContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`
export default FilterTagsForm