import { useAssistantRequest } from "../../apis/assistant/useAssistantRequest"


export const useAssistantHandler = () => {
    const { generateMessage } = useAssistantRequest()
    

    const generateMessageHandler = async (payload: any) => {

        const result = await generateMessage(payload)

        if(result) return result.newMesage
    }

    return {
        generateMessageHandler
    }
}
