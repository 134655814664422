import { FunctionComponent, useCallback } from 'react'
import Row from '../../../../components/Row'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import Button from '../../../../components/Button'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { useTemplateStore } from '../../../../stores/templates/useTemplateStore'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import Input from '../../../../components/Input'
import styled from 'styled-components'
import { useTemplateHandlers } from '../../../../services/actions/template/useTemplateHandlers'
import debounce from 'lodash.debounce'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { useAuthHandlers } from '../../../../services/actions/auth/useAuthHandlers'
import DropdownItem from '../../../../components/dropdown/DropdownItem'
import DropdownMenu from '../../../../components/dropdown/DropdownMenu'
import { styles } from '../../../../styles/themes/style'
import FilterButton from '../../../../fragments/FilterButton'
import Column from '../../../../components/Column'
import TemplatesBulkActionsDropdown from '../../../../fragments/dropdowns/TemplatesBulkActionsDropdown'

interface ITemplatesControlBarProps {
    controlBarHeight?: number;
    templatesParameters?: any;
    templatesFilters?: any;
}

interface ITemplatesControlBarProps {
    workspaceUsers?: any
}


interface IRowStyle {
    showMobile?: boolean
}

const TemplatesControlBar: FunctionComponent<ITemplatesControlBarProps> = ({ templatesParameters, templatesFilters }) => {
    const { openSidebar } = useAppHandlers()
    const { saveUserPreferencesHandler } = useAuthHandlers()
    const { setTemplatesFiltersHandler, setTemplatesParametersHandler, getTemplatesHandler, bulkDeleteTemplatesHandler, bulkUpdateAccessTemplatesHandler , numberOfTemplatesFiltersHandler, setClearTemplatesFilterHandler } = useTemplateHandlers()

    const { store: { user, workspace } } = useAuthStore()

    const { page, pageSize, total } = templatesParameters
    const { selectedTemplatesIds, searchByName, isAllSelected } = templatesFilters

    const searchByNameHandler = useCallback(debounce((value: string) => { 
        setTemplatesFiltersHandler({...templatesFilters, searchByName: value})
    }, 500), [])

    const isDefaultTemplates = user?.preferences?.hideDefaultTemplates

    const onHideDefaultTemplatesHandler = async () => {
        await saveUserPreferencesHandler({hideDefaultTemplates: !isDefaultTemplates})
        getTemplatesHandler()
    }

    const onClickHandler = (props: boolean) => {
        bulkUpdateAccessTemplatesHandler(props)
        setTemplatesFiltersHandler({...templatesFilters, isAllSelected: false, selectedTemplatesIds: []})
    }

    const resetFilterHandler = useCallback(() => {
        setClearTemplatesFilterHandler();
    }, [templatesFilters, setClearTemplatesFilterHandler]);

    return <Row gap='8px' alignItems spaceBetween flexWrap>
        <Row gap="8px">
            <Row gap="8px">
                <Button $type='blue' onClickHandler={() => openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template' })}>{'Add New Template'}</Button>
                <StyledDropdownMenu className='dropdownWrapperReference' disabled={(selectedTemplatesIds.length === 0 && !isAllSelected) || !workspace.isBusiness} SVGtype={!workspace.isBusiness ? 'lock2' : ''} tooltipMessage={!workspace.isBusiness ? 'Upgrade to Business to unlock this feature' : ''} title={'Update Access'}> 
                    <DropdownItem onClickHandler={() => onClickHandler(true)}>Private</DropdownItem>
                    <DropdownItem onClickHandler={() => onClickHandler(false)}>Public</DropdownItem>
                </StyledDropdownMenu>
            </Row>
            <Row gap="8px" alignItems>
                <ResponsiveContainerSmall>
                    <Button 
                        $type='white' 
                        $customWidth
                        $color={styles.colors.primary600}
                        $SVGtype={isDefaultTemplates ? 'showTemplates' : 'hideTemplates'} 
                        onClickHandler={onHideDefaultTemplatesHandler}>
                            {`${isDefaultTemplates ? 'Show' : 'Hide'} default templates`}
                    </Button>
                </ResponsiveContainerSmall>
                <Divider />
                <ActionDropdownContainer>
                        <TemplatesBulkActionsDropdown />
                </ActionDropdownContainer>
                <ResponsiveContainerMeduim>
                    <Button tooltipMessage='' 
                        $customWidth 
                        disabled={selectedTemplatesIds.length === 0 && !isAllSelected} 
                        $type='white' $SVGtype='trashCan' $color={styles.colors.red600} 
                        onClickHandler={bulkDeleteTemplatesHandler}>{`Delete templates`}
                    </Button>
                </ResponsiveContainerMeduim>
            </Row>
        </Row>
       <Row gap='12px'>
            <TablePageNavigation
                page={page ?? 1}
                pageSize={pageSize ?? 25}
                totalCount={total}
                onPageChanged={(value: number) => setTemplatesParametersHandler({...templatesParameters, page: value})}
                onPageSizeChanged={(value: number) => setTemplatesParametersHandler({...templatesParameters, pageSize: value})}
            />
            <div style={{ width: 'auto' }}><Input prefilledValue={searchByName} placeholder="Search template name ..." onChangeHandler={(e: any) => searchByNameHandler(e)} /></div>
            <FilterButton
                sidebar={SidebarTypes.TEMPLATES_FILTER}
                numberOfFilters={numberOfTemplatesFiltersHandler()}
                resetFilter={() => resetFilterHandler()}
            />
        </Row>
    </Row>
}

const StyledDropdownMenu = styled(DropdownMenu)`
    height: 34px;
    background-color: ${styles.colors.white};

    ${({disabled}) => disabled && `
            & .dropdownWrapperReference {
                color: ${styles.colors.black200};

                svg { cursor: default; }
                
                &:hover { color: ${styles.colors.black200}; }
            }
        `
    }
    
    & .dropdown-toggle {
        padding: 0 7px;
    }
`
const Divider = styled.div`
    width: 2px;
    height: 18px;
    background-color: #DAE0E8;
`
const ResponsiveContainerMeduim = styled(Row)<IRowStyle>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @media (max-width: 1336px) {
        display: none;
    }
`
const ActionDropdownContainer = styled(Row)<IRowStyle>`
    display: none;

    @media (max-width: 1336px) {
        display: block;
    }
`
const ResponsiveContainerSmall = styled(Row)<IRowStyle>`
    display: flex;
    flex-direction: row;
    gap: 8px;

    @media (max-width: 1260px) {
        display: none;
    }
`

export default TemplatesControlBar