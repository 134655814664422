import { TAssistantState, TAssistantAction, ActionType } from "../../interfaces/store/assistantStore";
import { makeStore } from "../makeStore";


const initialState: TAssistantState = {
    sparkleDropdown: false,
    originalMessage: '',
    selectedTone: '',
    fetchingAiMessage: false
}

const reducer = (state: TAssistantState, action: TAssistantAction): TAssistantState => {
    switch (action.type) {
        case ActionType.SET_SPARKLE_DROPDOWN: return { ...state, sparkleDropdown: action.payload }
        case ActionType.SET_ORIGINAL_MESSAGE: return { ...state, originalMessage: action.payload }
        case ActionType.SET_SELECTED_TONE: return { ...state, selectedTone: action.payload }
        case ActionType.SET_FETCHING_AI_MESSAGE: return { ...state, fetchingAiMessage: action.payload }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: AssistantStoreProvider, useStoreContext: useAssistantStore } = makeStore(reducer, initialState)

export { AssistantStoreProvider, useAssistantStore }