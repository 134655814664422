export const languages = [
    { value : 'ab', label : 'Abkhazian' },
    { value : 'aa', label : 'Afar' },
    { value : 'af', label : 'Afrikaans' },
    { value : 'ak', label : 'Akan' },
    { value : 'sq', label : 'Albanian' },
    { value : 'am', label : 'Amharic' },
    { value : 'ar', label : 'Arabic' },
    { value : 'an', label : 'Aragonese' },
    { value : 'hy', label : 'Armenian' },
    { value : 'as', label : 'Assamese' },
    { value : 'av', label : 'Avaric' },
    { value : 'ae', label : 'Avestan' },
    { value : 'ay', label : 'Aymara' },
    { value : 'az', label : 'Azerbaijani' },
    { value : 'bm', label : 'Bambara' },
    { value : 'ba', label : 'Bashkir' },
    { value : 'eu', label : 'Basque' },
    { value : 'be', label : 'Belarusian' },
    { value : 'bn', label : 'Bengali' },
    { value : 'bh', label : 'Bihari languages' },
    { value : 'bi', label : 'Bislama' },
    { value : 'bs', label : 'Bosnian' },
    { value : 'br', label : 'Breton' },
    { value : 'bg', label : 'Bulgarian' },
    { value : 'my', label : 'Burmese' },
    { value : 'ca', label : 'Catalan, Valencian' },
    { value : 'km', label : 'Central Khmer' },
    { value : 'ch', label : 'Chamorro' },
    { value : 'ce', label : 'Chechen' },
    { value : 'ny', label : 'Chichewa, Chewa, Nyanja' },
    { value : 'zh', label : 'Chinese' },
    { value : 'cv', label : 'Chuvash' },
    { value : 'kw', label : 'Cornish' },
    { value : 'co', label : 'Corsican' },
    { value : 'cr', label : 'Cree' },
    { value : 'hr', label : 'Croatian' },
    { value : 'cs', label : 'Czech' },
    { value : 'da', label : 'Danish' },
    { value : 'dv', label : 'Divehi, Dhivehi, Maldivian' },
    { value : 'nl', label : 'Dutch, Flemish' },
    { value : 'dz', label : 'Dzongkha' },
    { value : 'en', label : 'English' },
    { value : 'eo', label : 'Esperanto' },
    { value : 'et', label : 'Estonian' },
    { value : 'ee', label : 'Ewe' },
    { value : 'fo', label : 'Faroese' },
    { value : 'fj', label : 'Fijian' },
    { value : 'fi', label : 'Finnish' },
    { value : 'fr', label : 'French' },
    { value : 'ff', label : 'Fulah' },
    { value : 'gd', label : 'Gaelic, Scottish Gaelic' },
    { value : 'gl', label : 'Galician' },
    { value : 'lg', label : 'Ganda' },
    { value : 'ka', label : 'Georgian' },
    { value : 'de', label : 'German' },
    { value : 'ki', label : 'Gikuyu, Kikuyu' },
    { value : 'el', label : 'Greek (Modern)' },
    { value : 'kl', label : 'Greenlandic, Kalaallisut' },
    { value : 'gn', label : 'Guarani' },
    { value : 'gu', label : 'Gujarati' },
    { value : 'ht', label : 'Haitian, Haitian Creole' },
    { value : 'ha', label : 'Hausa' },
    { value : 'he', label : 'Hebrew' },
    { value : 'hz', label : 'Herero' },
    { value : 'hi', label : 'Hindi' },
    { value : 'ho', label : 'Hiri Motu' },
    { value : 'hu', label : 'Hungarian' },
    { value : 'is', label : 'Icelandic' },
    { value : 'io', label : 'Ido' },
    { value : 'ig', label : 'Igbo' },
    { value : 'id', label : 'Indonesian' },
    { value : 'ie', label : 'Interlingue' },
    { value : 'iu', label : 'Inuktitut' },
    { value : 'ik', label : 'Inupiaq' },
    { value : 'ga', label : 'Irish' },
    { value : 'it', label : 'Italian' },
    { value : 'ja', label : 'Japanese' },
    { value : 'jv', label : 'Javanese' },
    { value : 'kn', label : 'Kannada' },
    { value : 'kr', label : 'Kanuri' },
    { value : 'ks', label : 'Kashmiri' },
    { value : 'kk', label : 'Kazakh' },
    { value : 'rw', label : 'Kinyarwanda' },
    { value : 'kv', label : 'Komi' },
    { value : 'kg', label : 'Kongo' },
    { value : 'ko', label : 'Korean' },
    { value : 'kj', label : 'Kwanyama, Kuanyama' },
    { value : 'ku', label : 'Kurdish' },
    { value : 'ky', label : 'Kyrgyz' },
    { value : 'lo', label : 'Lao' },
    { value : 'la', label : 'Latin' },
    { value : 'lv', label : 'Latvian' },
    { value : 'lb', label : 'Letzeburgesch, Luxembourgish' },
    { value : 'li', label : 'Limburgish, Limburgan, Limburger' },
    { value : 'ln', label : 'Lingala' },
    { value : 'lt', label : 'Lithuanian' },
    { value : 'lu', label : 'Luba-Katanga' },
    { value : 'mk', label : 'Macedonian' },
    { value : 'mg', label : 'Malagasy' },
    { value : 'ms', label : 'Malay' },
    { value : 'ml', label : 'Malayalam' },
    { value : 'mt', label : 'Maltese' },
    { value : 'gv', label : 'Manx' },
    { value : 'mi', label : 'Maori' },
    { value : 'mr', label : 'Marathi' },
    { value : 'mh', label : 'Marshallese' },
    { value : 'ro', label : 'Moldovan, Moldavian, Romanian' },
    { value : 'mn', label : 'Mongolian' },
    { value : 'na', label : 'Nauru' },
    { value : 'nv', label : 'Navajo, Navaho' },
    { value : 'nd', label : 'Northern Ndebele' },
    { value : 'ng', label : 'Ndonga' },
    { value : 'ne', label : 'Nepali' },
    { value : 'se', label : 'Northern Sami' },
    { value : 'no', label : 'Norwegian' },
    { value : 'nb', label : 'Norwegian Bokmål' },
    { value : 'nn', label : 'Norwegian Nynorsk' },
    { value : 'ii', label : 'Nuosu, Sichuan Yi' },
    { value : 'oc', label : 'Occitan (post 1500)' },
    { value : 'oj', label : 'Ojibwa' },
    { value : 'or', label : 'Oriya' },
    { value : 'om', label : 'Oromo' },
    { value : 'os', label : 'Ossetian, Ossetic' },
    { value : 'pi', label : 'Pali' },
    { value : 'pa', label : 'Panjabi, Punjabi' },
    { value : 'ps', label : 'Pashto, Pushto' },
    { value : 'fa', label : 'Persian' },
    { value : 'pl', label : 'Polish' },
    { value : 'pt', label : 'Portuguese' },
    { value : 'qu', label : 'Quechua' },
    { value : 'rm', label : 'Romansh' },
    { value : 'rn', label : 'Rundi' },
    { value : 'ru', label : 'Russian' },
    { value : 'sm', label : 'Samoan' },
    { value : 'sg', label : 'Sango' },
    { value : 'sa', label : 'Sanskrit' },
    { value : 'sc', label : 'Sardinian' },
    { value : 'sr', label : 'Serbian' },
    { value : 'sn', label : 'Shona' },
    { value : 'sd', label : 'Sindhi' },
    { value : 'si', label : 'Sinhala, Sinhalese' },
    { value : 'sk', label : 'Slovak' },
    { value : 'sl', label : 'Slovenian' },
    { value : 'so', label : 'Somali' },
    { value : 'st', label : 'Sotho, Southern' },
    { value : 'nr', label : 'South Ndebele' },
    { value : 'es', label : 'Spanish, Castilian' },
    { value : 'su', label : 'Sundanese' },
    { value : 'sw', label : 'Swahili' },
    { value : 'ss', label : 'Swati' },
    { value : 'sv', label : 'Swedish' },
    { value : 'tl', label : 'Tagalog' },
    { value : 'ty', label : 'Tahitian' },
    { value : 'tg', label : 'Tajik' },
    { value : 'ta', label : 'Tamil' },
    { value : 'tt', label : 'Tatar' },
    { value : 'te', label : 'Telugu' },
    { value : 'th', label : 'Thai' },
    { value : 'bo', label : 'Tibetan' },
    { value : 'ti', label : 'Tigrinya' },
    { value : 'to', label : 'Tonga (Tonga Islands)' },
    { value : 'ts', label : 'Tsonga' },
    { value : 'tn', label : 'Tswana' },
    { value : 'tr', label : 'Turkish' },
    { value : 'tk', label : 'Turkmen' },
    { value : 'tw', label : 'Twi' },
    { value : 'ug', label : 'Uighur, Uyghur' },
    { value : 'uk', label : 'Ukrainian' },
    { value : 'ur', label : 'Urdu' },
    { value : 'uz', label : 'Uzbek' },
    { value : 've', label : 'Venda' },
    { value : 'vi', label : 'Vietnamese' },
    { value : 'vo', label : 'Volap_k' },
    { value : 'wa', label : 'Walloon' },
    { value : 'cy', label : 'Welsh' },
    { value : 'fy', label : 'Western Frisian' },
    { value : 'wo', label : 'Wolof' },
    { value : 'xh', label : 'Xhosa' },
    { value : 'yi', label : 'Yiddish' },
    { value : 'yo', label : 'Yoruba' },
    { value : 'za', label : 'Zhuang, Chuang' },
    { value : 'zu', label : 'Zulu' }
];