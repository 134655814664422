import { FunctionComponent, useState, useEffect, ReactNode } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { styles } from '../styles/themes/style';
import DropdownMenu from '../components/dropdown/DropdownMenu';
import DropdownItem from '../components/dropdown/DropdownItem';
import TabsSearch from '../components/TabsSearch';
import { ITabProperties } from '../components/TabsView';
import Row from '../components/Row';
import Checkbox from '../components/Checkbox';
import SvgFragment from './SvgFragment';

export interface IAdvancedFilterOption {
    value: string,
    label: ReactNode,
    id: string,
    selected: boolean,
    object: Object
}

interface IAdvancedFilterFragmentProps {
    customSingleValue?: any
    upgradePlan?: boolean
    drop?: string
    filterOptions: IAdvancedFilterOption[];
    selectedItems: any[];
    infoTitle: string;
    inputTitle: string;
    tabs: ITabProperties[];
    showSearchField?: boolean;
    inputSelect?: boolean;
    resetDisabled?: boolean;
    styledRow?: boolean;
    onClickedItemHandler?: (item: any) => void;
    onSelectedItemHandler: (items: any[], item?: any) => void;
    onResetSelectionsHandler: () => void;
    onSelectedTabHandler?: (tab: any) => void;
    onRemovedItemHandler?: (items: any[], item?: any) => void;
}

const AdvancedFilterFragment: FunctionComponent<IAdvancedFilterFragmentProps> = ({ upgradePlan, customSingleValue, drop, filterOptions, selectedItems, infoTitle, inputTitle, tabs, showSearchField = true, inputSelect = false, styledRow, onClickedItemHandler, onSelectedItemHandler, onResetSelectionsHandler, onSelectedTabHandler, onRemovedItemHandler }) => {
 
    const [selectOptions, setSelectOptions] = useState<IAdvancedFilterOption[]>([]);
    const [searchByNameValue, setSearchByNameValue] = useState('');
    const theme: DefaultTheme = useTheme();
    //todo: adjust other styles to the new design and use Text for all the labels
    const dropdownButton = <StyledControlDiv
                numberOfSelectedItems={selectedItems.length ?? 0}
            > {customSingleValue ? customSingleValue :
                <>{inputTitle}:&nbsp;
                {
                    (selectedItems.length) ?
                        <span style={{ color: styles.colors.primary600, fontWeight: 'bold' }}>
                            {selectedItems.length ?? 0}&nbsp;Selected
                        </span>
                        :
                        <span style={{ color: styles.colors.black600, fontWeight: 'bold' }}>Show All</span>
                }</>
            }
            </StyledControlDiv>

    //set prefilled values
    useEffect(() => {
        setSelectOptions(filterOptions?.map((option) => ({...option, selected: selectedItems?.some((item) => item._id === option.id)})));
        setSearchByNameValue('');
    }, [selectedItems, filterOptions]);

    const searchByNameHandler = (value: string) => {
        setSearchByNameValue(value);
        setSelectOptions(filterOptions.filter(option => option.value.toLowerCase().includes(value.toLowerCase())));
    };

    const toggleItemHandler = (event: any, item: IAdvancedFilterOption) => {
        event.stopPropagation()
        let add: boolean
        let selectedObjects: any[] = []
        setSelectOptions(selectOptions.map((option) => {
            if(option.id === item.id) {
                add = !option.selected
                let newOption = {...option, selected: add}
                if(newOption.selected) selectedObjects.push(newOption.object)
                return newOption
            }
            if(option.selected) selectedObjects.push(option.object)
            return {...option}
        }))
        onSelectedItemHandler(selectedObjects, item)
        onClickedItemHandler && onClickedItemHandler(item)
    }

    return (
        <Wrapper>
            {upgradePlan ? <UpgradePlanWrapper alignItems>{dropdownButton}<SvgFragment type='dropdownArrow' /></UpgradePlanWrapper> :
            <DropdownMenu
                    title={dropdownButton}
                    autoClose='outside'
                    drop={drop}
                    selectStyle
                >   
                    <TabsSearch 
                        inputSelect={inputSelect}
                        tabs={tabs}
                        infoTitle={infoTitle}
                        searchByNameValue={searchByNameValue}
                        selectedItems={selectedItems}
                        searchByNameHandler={showSearchField ? searchByNameHandler : undefined}
                        setSelectedTab={(e:any) => onSelectedTabHandler && onSelectedTabHandler(e)}
                        onRemovedItemHandler={onRemovedItemHandler && onRemovedItemHandler}
                        resetDisabled={!selectedItems.length}
                        resetSelectedValues={onResetSelectionsHandler}
                    >
                         <>
                         {selectOptions?.sort((a, b) => {
                            if(a.id === 'noTags') return -1
                            if(b.id === 'noTags') return 1 
                            return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
                         }).map((item: IAdvancedFilterOption) => 
                            (styledRow ?
                                <DropdownItem $marginTop onClickHandler={(e: any) => toggleItemHandler(e, item)}>
                                    <StyledRow>
                                        <Checkbox $height={'24px'} checked={item.selected} checkboxId={item.id} />
                                        {item.label}
                                    </StyledRow>
                                </DropdownItem>
                                :
                                <DropdownItem $color={theme.primaryColor} onClickHandler={(e: any) => toggleItemHandler(e, item)}>
                                    <Row alignItems gap="10px">
                                        <Checkbox checked={item.selected} checkboxId={item.id} />
                                        {item.label}
                                    </Row>
                                </DropdownItem>
                            )
                        )}</>

                    </TabsSearch>
            </DropdownMenu>
        }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    & .actions-dropdown + div {
        width: 100%;
        padding: 16px 15px;
    }
    & .dropdown-item {
        padding: 0;
    }
`

const StyledControlDiv = styled.div<{ numberOfSelectedItems: number }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    padding-left: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: NunitoSans;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    user-select: none;
`;

const StyledRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`
const UpgradePlanWrapper = styled(Row)`
    width: 296px;
    border: 2px solid ${styles.colors.black200};
    border-radius: 4px;
    background-color: ${styles.colors.black100};
`
export default AdvancedFilterFragment;
