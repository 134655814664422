import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { styles } from "../../styles/themes/style";
import DropdownItem from "../../components/dropdown/DropdownItem";
import AiDropdownItem from "./components/AiDropdownItem";
import Input from "../../components/Input";
import { languages } from "./components/languages";

interface ISetTranslateProps {
    isDisabled?: boolean
    dropdown: string
    setDropdown: (e: string) => void
    generateMessage: (e: any, i: any) => void
}

const SetTranslate: FunctionComponent<ISetTranslateProps> = ({ isDisabled, dropdown, setDropdown, generateMessage }) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const onClickHandler = (e: any) => {
        setSearchTerm('')
        generateMessage('translate', e.label.toLowerCase())
    }

    return (
        <Container>
            <AiDropdownItem isDisabled={isDisabled} padding="10px 15px" isDropdownOpen={dropdown === 'translate'} showArrow onClick={setDropdown} label="Set translate" svg="translate" />
            {dropdown === 'translate' && <Dropdown> 
                <InputWrapper>
                    <Input prefilledValue={searchTerm} onChangeHandler={(e: any) => setSearchTerm(e)}/>
                </InputWrapper>
                <OptionsWrapper>
                {languages?.filter((option: any) => option?.label?.toLowerCase().startsWith(searchTerm?.toLowerCase()))
                        .map((option: any) => (
                            <DropdownItem $color={styles.colors.primary600} onClickHandler={(e: any) => onClickHandler(option)}>{option?.label}</DropdownItem>
                        ))}
                </OptionsWrapper>
            </Dropdown>}
        </Container>
    )
}

const Container = styled.div`
    & .set-tone {
        &:hover {
            background-color: ${styles.colors.black100};
        }
    }
`

const Dropdown = styled.div`
    padding: 3px 15px;
`
const InputWrapper = styled.div`
    margin: 8px 16px;
`
const OptionsWrapper = styled.div`
    overflow-y: auto;
    max-height: 130px;
`
export default SetTranslate