
//Enums
export enum ActionType {
    SET_SPARKLE_DROPDOWN = "SET_SPARKLE_DROPDOWN",
    SET_ORIGINAL_MESSAGE = "SET_ORIGINAL_MESSAGE",
    SET_SELECTED_TONE = "SET_SELECTED_TONE",
    SET_FETCHING_AI_MESSAGE = "SET_FETCHING_AI_MESSAGE",
    RESET_STORE = "RESET_STORE"
};

//States
export type TAssistantState = {
    sparkleDropdown: boolean,
    originalMessage: string,
    selectedTone: string,
    fetchingAiMessage: boolean
};

//Actions
interface ISparkleDropdownAction {
    type: ActionType.SET_SPARKLE_DROPDOWN;
    payload: TAssistantState["sparkleDropdown"];
}

interface IOriginalMessageAction {
    type: ActionType.SET_ORIGINAL_MESSAGE;
    payload: TAssistantState["originalMessage"];
}

interface ISelectedToneAction {
    type: ActionType.SET_SELECTED_TONE;
    payload: TAssistantState["selectedTone"];
}

interface IFetchingAiMessageAction {
    type: ActionType.SET_FETCHING_AI_MESSAGE;
    payload: TAssistantState["fetchingAiMessage"];
}

interface IResetStoreAction {
    type: ActionType.RESET_STORE;
  }


export type TAssistantAction = 
    ISparkleDropdownAction |
    IOriginalMessageAction |
    ISelectedToneAction |
    IFetchingAiMessageAction |
    IResetStoreAction