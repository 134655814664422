import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { styles } from '../styles/themes/style';

interface ICheckboxProps extends ICheckboxSizeProps{
    checked?: boolean,
    checkboxId: string,
    onClickHandler?: (e: any) => void,
    className?: string,
    disabled?: boolean
}

interface ICheckboxSizeProps {
    $height?: string
}

const Check = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
        <path fill="#FFF" fillRule="evenodd" d="M4.954 9.003l6.708-6.426c.228-.218.228-.572 0-.79l-.824-.79c-.228-.218-.598-.218-.825 0l-5.471 5.24-2.555-2.446c-.227-.218-.597-.218-.825 0l-.824.79c-.228.218-.228.572 0 .79l3.791 3.632c.228.218.597.218.825 0z" />
    </svg>
);

const Icon: any = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 16px;
    height: 16px;
    border: 2px solid #8C96AD; 
    border-radius: 2px;

    &:hover {
        border-color: ${styles.colors.primary600};
    }

    ${(props: any) => props.checked ? `
        background-color: ${styles.colors.primary600}; 
        border-color: ${props.theme.primary600}; 
    ` : ''}

    ${(props: any) => props.disabled ? `
        background-color: ${!props.checked ? '' : styles.colors.primary300};
        border-color:  ${!props.checked ? styles.colors.black200 : styles.colors.primary300};
        cursor: not-allowed;

            &:hover {
                border-color: ${!props.checked ? styles.colors.black200 : styles.colors.primary300};
            }
    ` : ''}
`;

const Checkbox: FunctionComponent<ICheckboxProps> = ({ checked, checkboxId, onClickHandler, disabled, ...props }) => {

    return (
        <CheckboxWrapper id={checkboxId} onClick={(e) => !disabled && onClickHandler && onClickHandler(e)} {...props}>
            <Icon
                checked={checked}
                disabled={disabled}
            >
                {checked && Check}
            </Icon>
        </CheckboxWrapper>
    )
}

const CheckboxWrapper = styled.div<ICheckboxSizeProps>`
  height: ${({ $height }) => $height ? `${$height}` : '34px'};
  user-select: none;
  cursor: ${(props: any) => props.disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
`;

export default Checkbox