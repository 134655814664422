import { FormEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Row from "../../components/Row";
import ActionText from "../../components/ActionText";
import Button from "../../components/Button";

import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { AppRoutes, SidebarTypes } from "../../interfaces/store/appStore";
import { useTemplateStore } from "../../stores/templates/useTemplateStore";
import FormControl from "../../components/FormControl";
import Select from "../../components/select/Select";
import { useTemplateHandlers } from "../../services/actions/template/useTemplateHandlers";
import SvgFragment from "../SvgFragment";
import Text from "../../components/Text";
import Column from "../../components/Column";
import DateComponent from "../../components/DateComponent";
import AdvancedFilterFragment from "../AdvancedFilterFragment";
import { IUser } from "../../interfaces/models/connection";
import ProfilePhoto from "../../components/ProfilePhoto";
import { useWorkspaceHandlers } from "../../services/actions/workspace/useWorkspaceHandlers";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { styles } from "../../styles/themes/style";
import { useNotesHandlers } from "../../services/actions/notes/useNotesHandlers";
import TooltipWrapper from "../../components/TooltipWrapper";

interface IFilterTemplatesFormProps { }

const templatesTypeOptions = [
    { value: null, label: 'All', component: <Text>All</Text> },
    { value: false, label: 'Public', component: <Text>Public</Text> },
    { value: true, label: 'Private', component: <Text>Private</Text> },
]

const FilterTemplatesForm: FunctionComponent<IFilterTemplatesFormProps> = () => {
    const { store: { templates, templatesFilters } } = useTemplateStore()
    const { store: { workspace } } = useAuthStore()

    const { closeSidebar } = useAppHandlers()
    const { workspaceUsersPublicAllHandler } = useNotesHandlers()
    const { setTemplatesFiltersHandler, setClearTemplatesFilterHandler } = useTemplateHandlers()

    const { templateAccessType, createdAtFrom, createdAtTo, usersMutualCondition, selectedUsersMutual } = templatesFilters

    const [workspaceUsers, setWorkspaceUsers] = useState<any>([])

    useEffect(() => {
        workspaceUsersHandler()
    }, [])

    const workspaceUsersHandler = async () => {
        const result = await workspaceUsersPublicAllHandler({
            condition: 'or',
            name: '',
            email: '',
            getAll: 'yes' 
        })
        setWorkspaceUsers(result)
    }

    const setSelectedUsersMutual = (newlySelectedUsers: IUser[]) => {
        const updatedFilter = {
            ...templatesFilters,
            selectedUsersMutual: [...newlySelectedUsers]
        };
        setTemplatesFiltersHandler(updatedFilter);
    }

    const resetSelectedUsersMutual = () => {
        const updatedFilter = {
            ...templatesFilters,
            selectedUsersMutual: []
        };
        setTemplatesFiltersHandler(updatedFilter);
    }

    const showResults = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        closeSidebar(SidebarTypes.TEMPLATES_FILTER)
    }, [templatesFilters])

    const updateForm = useCallback((control: any, newValue: string) => {
        const updatedFilter = {
            ...templatesFilters,
            [control]: newValue
        }

        setTemplatesFiltersHandler(updatedFilter)
    }, [templatesFilters, setTemplatesFiltersHandler])

    const actionText = !workspace.isBusiness && 
        <StyledActionText icon='lock2' onClickHandler={(e: any) => {
            e.stopPropagation()
            window.open(`${window.location.origin}${AppRoutes.BILLING}`, '_blank')
        }}>
            {'Upgrade'}
        </StyledActionText>

    return (
        <StyledForm>
            <FormControl $orientation="column">
                <AdvancedFilterFragment
                    upgradePlan={!workspace.isBusiness}
                    customSingleValue={selectedUsersMutual.length === 0 &&
                        <StyledRow alignItems gap="5px" spaceBetween>
                            <Row gap="5px">
                                <Text $bold $active={workspace.isBusiness} $faded={!workspace.isBusiness}>Owner: </Text>
                                <Text $bold $faded={!workspace.isBusiness}>Show All</Text>
                            </Row>
                            {actionText}
                        </StyledRow>
                    }
                    styledRow={true}
                    filterOptions={workspaceUsers.map((user: IUser) => ({
                        value: `${user.firstName} ${user.lastName}`,
                        label: <StyledContainer>
                            <ProfilePhoto
                                tooltipMessage={`${user?.firstName} ${user?.lastName}`}
                                capitalLetters={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`} />
                            <Column>
                                <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                                <Text $paragraph3 $placeholder $ellipsis="0">{user?.email}</Text>
                            </Column>
                        </StyledContainer>,
                        id: user._id,
                        selected: false,
                        object: user
                    }))}
                    selectedItems={selectedUsersMutual}
                    infoTitle="Choose whether you want to get items that have all the selected notes (AND) or any of the selected notes (OR)."
                    inputTitle="Owner"
                    tabs={[{ title: 'Or', slug: 'Or', selected: usersMutualCondition === 'Or' }, { title: 'And', slug: 'And', selected: usersMutualCondition !== 'Or' }]}
                    onSelectedTabHandler={(tab: string) => updateForm('usersMutualCondition', tab)}
                    onSelectedItemHandler={setSelectedUsersMutual}
                    onResetSelectionsHandler={resetSelectedUsersMutual}
                />
            </FormControl>
            <FormControl $orientation="column">
                <Select
                    isDisabled={!workspace.isBusiness}
                    $components
                    isSearchable={false}
                    options={templatesTypeOptions}
                    value={{
                        ...templateAccessType,
                        component:
                            <StyledRow alignItems gap="5px" spaceBetween>
                                {templateAccessType ? 
                                    <Row gap="5px">
                                        <Text $bold $active={workspace.isBusiness} $faded={!workspace.isBusiness}>{`Type:`}</Text>
                                        <Text $bold $faded={!workspace.isBusiness}>{templateAccessType.label}</Text>
                                    </Row> 
                                    :
                                    <Text $bold $active={workspace.isBusiness}>{'Type'}</Text>}
                                {actionText}
                            </StyledRow>
                    }}
                    onChange={(e: any) => updateForm('templateAccessType', e)}
                />
            </FormControl>
            <FormControl $orientation="column">
                <DateComponent
                    title='Date Created'
                    dateFrom={createdAtFrom}
                    dateTo={createdAtTo}
                    dateFromValueChangedHandler={(e: any) => setTemplatesFiltersHandler({ ...templatesFilters, createdAtFrom: e })}
                    dateToValueChangedHandler={(e: any) => setTemplatesFiltersHandler({ ...templatesFilters, createdAtTo: e })}
                    resetFilter={() => setTemplatesFiltersHandler({ ...templatesFilters, createdAtFrom: '', createdAtTo: '' })}
                />
            </FormControl>
            <div style={{marginTop: '16px'}}>
                <Row gap="10px" spaceBetween>
                    <ActionText danger onClickHandler={() => setClearTemplatesFilterHandler()}>Clear filters</ActionText>
                    <Row gap="10px" alignItems>
                        <Button $mediumButton $type="blue" onClickHandler={(e: any) => showResults(e)}>Results</Button>
                    </Row>
                </Row>
            </div>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`
const StyledContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 4px;
`
const StyledActionText = styled(ActionText)`
    span {    
        color: ${styles.colors.black300};
        font-size: 12px;
    }

    &:hover{
        path { fill: ${styles.colors.primary400} }
    }

    div {
        margin-right: 4px;
        svg {
            margin-bottom: 4px;
        }
    }
`
const StyledRow = styled(Row)`
    width: 260px;
`
export default FilterTemplatesForm